import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import { cond, equals, T, F, isNil } from 'ramda';
import Cookies from 'js-cookie';
import { PlanService, Segment, ServiceType, TServiceType } from '../components/containers/BroadbandPlanCard/types';
import { HOSTNAME, ENVIRONMENT, FEATURES, LOCAL_IMAGES_HOST } from './const';
import { EMAIL_REGEX } from './regex';
import { TECHNOLOGY } from '../components/containers/BroadbandPlanCard/constants';

export const getEnvironment = (): string => {
  const hostname = window && window.location && window.location.hostname;
  switch (hostname) {
    case HOSTNAME.DEV:
      return ENVIRONMENT.DEV;
    case HOSTNAME.TEST:
      return ENVIRONMENT.TEST;
    case HOSTNAME.PREPROD:
      return ENVIRONMENT.PREPROD;
    case HOSTNAME.PROD:
      return ENVIRONMENT.PROD;
    default:
      return ENVIRONMENT.LOCALHOST;
  }
};

export const nestedStyles = (selector: string, baseSelector: string, generateSubtreeStyles: Function) => {
  if (selector[0] === ' ') {
    const tag = selector.slice(1);
    const nestedTag = generateSubtreeStyles(`${baseSelector} ${tag}`);
    return nestedTag;
  }
  if (selector[0] === '>') {
    const tag = selector.slice(1);
    const nestedTag = generateSubtreeStyles(`${baseSelector} > ${tag}`);
    return nestedTag;
  }
  return null;
};

/** *
 * Get contrast color based on the hex color.
 * @param {string} hexcolor
 * @returns {boolean} true if the color is darker then 50% of the color pallette
 */
export const getContrastYIQ = (hexcolor: string): boolean => {
  if (!hexcolor) {
    return null;
  }

  const nextHexColor = hexcolor.replace('#', '');
  const r = parseInt(nextHexColor.substr(0, 2), 16);
  const g = parseInt(nextHexColor.substr(2, 2), 16);
  const b = parseInt(nextHexColor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return !(yiq >= 128);
};

/** *
 * Returns true if this is a local environment
 * @returns {boolean} true if runs on localhost
 */
export const isLocal = (): boolean => window.location.hostname === 'localhost';

/**
 * Returns host to the be used when displaying images in local environment
 */
export const getLocalImageHost = (): string => (isLocal() ? LOCAL_IMAGES_HOST : '');

/**
 * Returns display price with 2 digits after decimal point from number with one or no digits after decimal point
 * @param {number} value to display
 * @param {boolean} removeDecimalsWhenZeroes return price string without decimals when decimal parts are all zeroes
 * @returns {string} display price or empty string if value not provided
 */
export const getDisplayPrice = (value: number, removeDecimalsWhenZeroes: boolean = false): string => {
  return (
    value?.toFixed(removeDecimalsWhenZeroes && value % 1 < 0.01 ? 0 : 2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') || ''
  );
};

/**
 * Returns price with dollar sign. e.g., Positive: '$10.89', Negative: '-$10.89'
 * @param value formatted string value of positive or negative number
 * @returns price with dollar sign
 */
export const buildPriceWithDollar = (value: string) => {
  if (value.charAt(0) === '-') {
    return value.replace('-', '-$');
  }

  return `$${value}`;
};

export const generateKey = (value: string) => {
  return (value || '')
    .split(' ')
    .join('')
    .toLowerCase();
};

/**
 * Prevents body from scrolling. Used when popping up modals.
 * @param isFixed - should the body be fixed
 */
export const toggleBodyScrollHidden = (isFixed: boolean) => {
  document.body.style.overflow = isFixed ? 'hidden' : 'auto';
};

export const toggleBodyScrollFixed = (isFixed: boolean) => {
  document.body.style.position = isFixed ? 'fixed' : '';
};

/**
 * Pluralizes the word depending on the quantity
 * @param word - the word to pluralize
 * @param quantity - the quantity to pluralize for
 */
export const pluralize = (word: string, quantity: number) => {
  const exceptionWords = [{ singular: 'this', plural: 'these' }]; // add your exceptions here
  const exception = find(exceptionWords, { singular: word });

  if (exception) {
    return quantity === 1 ? exception.singular : exception.plural;
  }

  return `${word}${quantity === 1 ? '' : 's'}`;
};

/**
 * Checks if the given element is in the viewport
 * @param element the element to check
 */
export const isInViewport = (element: HTMLElement) => {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

/**
 * Returns the enabled features as an array of strings so that FeatureToggles can understand it
 */
export const getFeatures = () => {
  return Object.keys(FEATURES).map((key: string) => {
    // @ts-ignore
    // TODO: implement better typing for FEATURES
    return FEATURES[key].enabled ? FEATURES[key].name : false;
  });
};

/**
 * Checks if service is available for plan.
 * @param service
 * @param broadBandPlan
 */
export const checkService = ({ type, provider, rat }: TServiceType, plan: PlanService) => {
  return (
    type === plan?.serviceType &&
    (isEmpty(plan?.provider) || plan?.provider.includes(provider.toLowerCase())) &&
    (isEmpty(plan.rat) || rat === plan.rat)
  );
};

/**
 * Checks which kind of technology is being used from product data
 * @param broadbandData
 */
export const getTechnology = (serviceType: ServiceType) => {
  if ([ServiceType.ADSL, ServiceType.VDSL].includes(serviceType)) {
    return TECHNOLOGY.copper;
  }

  const technologyMapping = {
    [ServiceType.ADSL]: TECHNOLOGY.copper,
    [ServiceType.VDSL]: TECHNOLOGY.copper,
    [ServiceType.Hyperfibre]: TECHNOLOGY.hyperfibre,
    [ServiceType.Fibre]: TECHNOLOGY.fibre,
  };

  return technologyMapping[serviceType as keyof typeof technologyMapping] || TECHNOLOGY.wireless;
};

type Capacity = {
  dataCap: number;
  planDataAmount: string;
};

/**
 * Checks plan has a data allowance less or equal to the service capacity (max GB).
 * @param service
 * @param plan
 */
export const checkCapacity = ({ maxGigaBytes }: TServiceType, { dataAllowance }: PlanService) => {
  const capacity: Capacity = { dataCap: maxGigaBytes, planDataAmount: dataAllowance };

  return cond<Capacity, boolean>([
    [({ dataCap }) => isNil(dataCap), T],
    [({ planDataAmount }) => isNil(planDataAmount), T],
    [({ dataCap }) => equals(dataCap)(0), F],
    [({ planDataAmount }) => isEmpty(planDataAmount), F],
    [({ dataCap, planDataAmount }) => dataCap >= Number(planDataAmount), T],
    [({ dataCap, planDataAmount }) => planDataAmount.toLowerCase() === 'unlimited' && dataCap >= 601, T],
    [T, F],
  ])(capacity);
};

/**
 * Check if number is an even number
 * @param number
 */
export const isEven = (num: number) => num % 2 === 0;

/**
 * Extracts the current breakpoint value from body content which is evaluated based on window size.
 * This is a solution to providing JavaScript based on breakpoints
 * and is not intended to replace CSS media queries. Use sparingly.
 */
export const getWindowBreakpoint = () => {
  return window.getComputedStyle(document.querySelector('body'), ':before').content.replace(/"/g, '');
};

/**
 * Accepts a string of number and evaluates its truthiness.
 * E.g. "0" will return false, "1" will return true
 * @param string
 */
export const isStrNumTrue = (value: string) => !!Number(value);

/**
 * Used to check if the current audience cookie is set to business
 * @returns true if the current_audience is set to business
 */
export const isBusinessContext = () => {
  return Cookies.get('current_audience') === 'business';
};

/**
 * Used to get the consumer segment which is needed by the address availability API
 * @returns either one of CostumerSegment enum
 */
export const getCustomerSegment = () => {
  return isBusinessContext() ? Segment.Business : Segment.Consumer;
};

/**
 * Used to check wether an input is a valid email or not
 * @returns true if its a valid email
 */
export const isValidEmail = (email: string): boolean => {
  return !!String(email)
    .toLowerCase()
    .match(EMAIL_REGEX);
};
