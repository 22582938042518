// WHITE_SPACE_REGEX matches any amount of white space
export const WHITE_SPACE_REGEX = / /g;

// HTTP_REGEX matches any combination of http, https with or without a colon, and any number of forward slashes
export const HTTP_REGEX = /^(?:https?:\/+)?(?:www\.)?/i;

// eslint-disable-next-line no-control-regex
export const EMAIL_REGEX = /^((([a-z]|\d|[-_]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[-_]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;

// EMAIL_REGEX_CHECKOUT is the same as EMAIL_REGEX except that it does not allow double quotes.
// eslint-disable-next-line no-control-regex
export const EMAIL_REGEX_CHECKOUT = /^([^"])((([^.][a-z]|\d|[-_]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[-_]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
/**
  CONTACT_NUMBER_REGEX will match against any string that begins with 09|06|07|04|03|02|9|7|6|4|3|2
  followed by more numbers. The entire string must be between 8 and 14 in length.
*/
export const CONTACT_NUMBER_REGEX = /(?=^[0-9]{8,14}$)(^(09|06|07|04|03|02|9|7|6|4|3|2)[0-9]+$)/;

/**
  NAME_REGEX will match against any string that:
  1. Begins with a letter from the English alphabet, upper or lower case.
  2. Ends wth any combination(must be at least one) of the following:
      2.1 Letters, upper or lower case from the English alphabet.
      2.2 Numbers.
      2.3 Full stops.
      2.4 Any unicode characters in range\u00C0 to \u017F
      2.5 Empty space, apostrophe, hyphen, provided that there is no more than one of them in a sequence
          eg: "John-Smith-" is acceptable but "John--Smith" is not.
*/
export const NAME_REGEX = /^[A-Za-z]((?!(-{2}| {2}|'{2}))[A-Za-z0-9\u00C0-\u017F. '-])+$/;

/**
  DRIVER_LICENCE_REGEX will match against any string that is two letters, 
  upper or lower case, followed by any six numbers.
  Eg: ab123456
*/
export const DRIVER_LICENCE_REGEX = /^[a-zA-Z]{2}[0-9]{6}$/;

/**
  DRIVER_LICENCE_VERSION_REGEX will match against three numbers in a row
  eg: 123
*/
export const DRIVER_LICENCE_VERSION_REGEX = /^[0-9]{3}$/;

/**
 *  PIN_REGEX:
 *  1. No repeating numbers. E.g. 0000,1111,2222,3333,4444,5555,6666,7777,8888,9999
 *  2. Cannot be any of the following combinations 8520,0258,2580,0852,0123,1112,1234,2345,3456,4567,5678,6789,7890,0987,9876,8765,7654,6543,5432,4321,3210
 *
 *  Tests: CheckoutIdentityForm.test
 */
export const PIN_REGEX = /^((?!(8520|0258|2580|0852|0123|1112|1234|2345|3456|4567|5678|6789|7890|0987|9876|8765|7654|6543|5432|4321|3210|0{4}|1{4}|2{4}|3{4}|4{4}|5{4}|6{4}|7{4}|8{4}|9{4}))[0-9]){4}$/;

/**
 *  PASSWORD_REGEX:
 *  1. must be 6 characters or more
 *  2. must contain at least 1 number and 1 letter
 *
 *  Tests: CheckoutIdentityForm.test
 */
export const PASSWORD_REGEX = /^(?=.*[0-9])(?=.*[a-zA-Z]).{6,}$/;

/**
 * Spaces, Alpha Numeric, Commas, dashes, slashes, full stops allowed
 */
export const SEARCH_ALLOW_REGEX = /^[a-zA-Z0-9,./\- ]*$/;
