import 'core-js/es/array/iterator';
import { registerApplication, start } from 'single-spa';
import { v1 as uuid } from 'uuid';
import * as Sentry from '@sentry/browser';
import { BrowserTracing } from "@sentry/tracing/dist/browser";
import { getEnvironment, getFeatures } from './src/util/util';
import { SENTRY_DSN, ENVIRONMENT } from './src/util/const';

import bootstrapComponent from './src/components';

const pageConfig = document.querySelector('#pageConfig')
  ? JSON.parse(document.querySelector('#pageConfig').textContent)
  : null;

// the webpack public_path variable needs to be set in order for the code splitting to work
if (pageConfig && pageConfig.resourceBaseUrl) {
  __webpack_public_path__ = pageConfig.resourceBaseUrl;
}

if (module.hot) {
  module.hot.accept();
}

Sentry.init({ 
  dsn: SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: getEnvironment(),
  tracesSampleRate: getEnvironment() === ENVIRONMENT.PROD ? 0.5 : 1.0,
  beforeSend(event, hint) {
    const error = hint.originalException;
    const moduleNotFoundErr = error && error.message && error.message.match(/cannot find module/i);
    if (moduleNotFoundErr) {
      return null;
    }
    // TODO: Disabled sentry logging by not returning the event, until we can resolve quota usage issue https://jira.2degrees.nz/browse/ONL-6605, uncomment this later
    // return event;
  },
});

document.querySelectorAll('.drupalComponent').forEach(item => {
  const { component } = item.dataset;
  
  registerApplication(
    'component-' + uuid(),
    () => bootstrapComponent(component),
    () => true,
    {
      ...item.dataset,
      features: getFeatures(),
      domElementGetter: () => item,
    }
  );
});

start();
