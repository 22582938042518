export const SENTRY_DSN = 'https://e81f960ff4ef4703a18c31b1578f500a@o240399.ingest.sentry.io/1845599';

export const ENVIRONMENT = Object.freeze({
  LOCALHOST: 'localhost',
  DEV: 'dev',
  TEST: 'test',
  PREPROD: 'preprod',
  PROD: 'prod',
});

export const HOSTNAME = Object.freeze({
  LOCALHOST: 'localhost',
  DEV: 'development.2degrees.nz',
  TEST: 'test.2degrees.nz',
  PREPROD: 'preproduction.2degrees.nz',
  PROD: 'www.2degrees.nz',
});

export const LOCAL_IMAGES_HOST = 'https://test.2degrees.nz';

export const COLORS = Object.freeze({
  WHITE: '#FFFFFF',
  BLACK: '#1D292E',
  GRAY: '#BBBFC0',
  HERO_BLUE_900: '#0070BF',
  HERO_BLUE_500: '#009ED8',
  HERO_BLUE_300: '#4DBBE4',
  HERO_BLUE_200: '#B3E2F3',
  SUNRISE_YELLOW_500: '#FFC528',
  SEAFOAM_GREEN_300: '#BFE5E2',
  DEEPSEA_BLUE_100: '#B3C3CF',
  DEEPSEA_BLUE_500: '#003660',
  FLAX_BLACK_50: '#E4E5E6',
});

export const PAYMENT_OPTIONS = Object.freeze({
  INTEREST_FREE: 'interestFree',
  BUY_OUTRIGHT: 'buyOutright',
});

export const URL_PARAMS = Object.freeze({
  SKU_ID: 'skuId',
});

export const STANDARD_SHADOW = '0px 5px 20px rgba(0, 78, 83, 0.1)';
export const FOCUS_SHADOW = '0 0 1px 4px rgba(0, 158, 216, 0.5)';
export const HOVER_SHADOW =
  '0px 8px 10px rgba(0, 0, 0, 0.2), 0px 16px 24px rgba(0, 0, 0, 0.14), 0px 0px 4px rgba(0, 0, 0, 0.08)';

export const CSS_TRANSITION = 'all 0.15s ease-in-out';

export const ADD_TO_CART_MODES = Object.freeze({
  DEFAULT: 'Default',
  LINK: 'Link',
  STATIC: 'Static',
  VOCUS: 'Vocus',
  CHECKOUT: 'Checkout',
});

export const LOCAL_URLS = Object.freeze({
  GET_SESSION: 'session.json',
  ADD_TO_CART: 'addToCart.json',
});

export const POST_REQUEST_HEADERS = {
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
};

export const CART_ITEM_FREQUENCY = Object.freeze({
  MONTH: 'month',
  FORTNIGHT: 'fortnight',
});

export const API_TIMEOUTS = Object.freeze({
  ADD_TO_CART_POST: 90000,
});

export const CART_API_RESPONSE_CODES = Object.freeze({
  SUCCESS: '0',
  PRODUCT_ERROR: '1',
  GENERIC_ERROR: '2',
  STOCK_ERROR: '3',
});

export const PRODUCT_PLAN_TYPES = ['monthly plan', 'prepay plan', 'shared plan', 'virtual-product'];

export const PLAN_TYPES = Object.freeze({
  MONTHLY: 'monthly plan',
  PREPAY: 'prepay plan',
  SHARED: 'shared plan',
  VIRTUAL: 'virtual-product',
});

export const PLAN_SEGMENTS = new Map([
  ['business', 'bb-sme'],
  ['consumer', 'bb-res'],
]);

// Form names are used to store and clear session data
export const FORM_NAMES = Object.freeze({
  CHECKOUT: 'checkout',
});

// Feature toggles. To be able to use these wrap the component into <FeatureToggles> provider
export const FEATURES = Object.freeze({
  CHECKOUT: { name: 'checkout', enabled: false },
  PLANSELECTOR_SPACE: { name: 'planselector-space', enabled: false },
  PLANSELECTOR_DISCLAIMER: { name: 'planselector-disclaimer', enabled: true },
  AA_BATTERY: { name: 'aa-battery', enabled: false },
});

export const CONTACT_PHONE_NUMBER = '0800 022 002';

export type AddToCartModes = keyof typeof ADD_TO_CART_MODES;

export const BRAND = Object.freeze({
  TWO_DEGREES: '2degrees',
});

export const PRODUCT_TYPE = Object.freeze({
  PLAN: 'Plan',
});

export const PRODUCT_CATEGORY = Object.freeze({
  BROADBAND: 'Broadband',
  WIRELESS_BROADBAND: 'Wireless Broadband',
});
